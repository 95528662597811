import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Link,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { ExternalIdTypes } from 'shared/src/constants/externalListings';

const fieldRenderers = {
  google: ({ value }) => {
    return (
      <Box>
        <Link target="_blank" href={`https://www.google.com/maps/place/?q=place_id:${value}`}>
          Google Place <ExternalLinkIcon />
        </Link>
      </Box>
    );
  },
  default: ({ sid, value }) => {
    return (
      <Box>
        {ExternalIdTypes.find(type => type.value === sid)?.label ?? sid}
        <Text>{value}</Text>
      </Box>
    );
  }
};

export default fieldRenderers;
