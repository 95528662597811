import { CloseIcon } from '@chakra-ui/icons';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  AspectRatio,
  Box,
  IconButton,
  Image,
} from '@chakra-ui/react';
import { Field } from 'react-final-form';
import imgixParams from 'web-react-ui/src/utils/imgixParams';
import Pips from './Pips';

const SuggestionImageCarousel = ({ value, onChange, editable }: {
  value: Array<string>, onChange?: (v: Array<string>) => void, editable?: boolean
}) => {
  const max = value?.length || 0;

  const [current, setCurrent] = useState(0);

  const nextImage = useMemo(
    () => () => setCurrent((v) => {
      return (v + 1) % max;
    }),
    [max],
  );

  const removeIndex = useMemo(
    () => (index: number) => {
      if (!onChange || !editable) return;
      const newValue = value.slice();
      newValue.splice(index, 1);
      onChange(newValue);
      setCurrent(index % (max - 1));
    },
    [value, max, onChange, editable],
  );

  return (
    <Box>
      <AspectRatio
        ratio={1}
        position="relative"
        maxW="375px"
        m="auto"
        borderRadius="2em"
        overflow="hidden"
        bg="gray.50"
      >
        <Box onClick={nextImage}>
          {(value || []).map((url, index) => {
            const croppedImageUrl = imgixParams(url, { w: 375, h: 375, fit: 'crop' });
            return (
              <Image key={index} src={croppedImageUrl} fit="cover" m="auto" hidden={index !== current} />
            );
          })}
          <IconButton
            icon={<CloseIcon />}
            position="absolute"
            top="1em"
            right="1em"
            borderRadius="full"
            onClick={() => removeIndex(current)}
            aria-label="Remove Image"
            display={editable ? 'initial' : 'none'}
          />
          <Pips current={current} max={max} position="absolute" bottom="1em" />
        </Box>
      </AspectRatio>
    </Box>
  );
};

const SuggestionImageCarouselField = ({ name, editable }: { name: string, editable?: boolean }) => {
  return (
    <Field name={name}>
      {({ input }) => (
        <SuggestionImageCarousel {...input} editable={editable} />
      )}
    </Field>
  );
};

export {
  SuggestionImageCarousel,
  SuggestionImageCarouselField,
};
