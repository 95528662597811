import PropTypes from 'prop-types';
import React, {
  useCallback,
  useState
} from 'react';
import {
  Link,
  NavLink
} from 'react-router-dom';
import { getLabelForType } from 'shared/src/constants/socialProfiles';

import usePromise from 'shared/src/hooks/usePromise';

import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Label from 'web-react-ui/src/components/elements/Label';
import ImageRenderer from 'web-react-ui/src/components/image/ImageRenderer';
import View from 'web-react-ui/src/components/layout/View';
import TagEditor from 'web-react-ui/src/components/tags/TagEditor';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import SubNav from 'web-react-ui/src/navigation/SubNav';
import iconizeImageUrl from 'web-react-ui/src/utils/iconizeImageUrl';

import ChangePlanModal from './ChangePlanModal';
import client from '../../services/client';
import usePropertySetting from '../../hooks/usePropertySettting';

const NavMenuItem = props => <SubNav.Item as={NavLink} activeClassName="active" {...props} />;

const BusinessProfileImage = ({ imageId, businessName }) => {
  if (!imageId) return null;
  return (
    <Request
      request={({ id }) => client.images.for(id).details()}
      params={{ id: imageId }}
    >
      {({ data, loaded }) => (
        <WaitFor waitFor={loaded}>
          <div className="mr1">
            <ImageRenderer imageUrl={iconizeImageUrl(data && data.url, { size: 48 })} name={businessName} size="lg" />
          </div>
        </WaitFor>
      )}
    </Request>
  );
};

BusinessProfileImage.propTypes = {
  imageId: PropTypes.string,
  businessName: PropTypes.string
};

function BusinessDetailsNav(props) {
  const {
    business,
    property,
    fetchTags,
    saveTags,
    onEnableBusiness,
    onDisableBusiness
  } = props;

  const phone = business && business.phone;
  const businessPhone = phone || 'No phone number set.';
  const businessCategories = business && business.categories;
  const businessSlogan = business && business.slogan;
  const showBullet = businessCategories && businessSlogan;

  const [showPlanModal, setShowPlanModal] = useState(false);
  const [refetchPlan, setRefetchPlan] = useState(0);
  const businessDashboardEnabled = usePropertySetting('business_dashboard.enabled');

  const actions = [
    { label: 'Create Offer', extraProps: { as: Link, to: './offers/create', disabled: !business.isEnabled } },
    { label: 'Suggest Offers', extraProps: { as: Link, to: `/${property.id}/businesses/${business.id}/offers/suggest`, disabled: !business.isEnabled } },
    { label: 'Review Offers', extraProps: { as: Link, to: `/${property.id}/businesses/${business.id}/offers/review`, disabled: !business.isEnabled } },
    { label: 'Change Plan', action: () => setShowPlanModal(true) },
    { label: 'Enable', action: onEnableBusiness, hidden: business.isEnabled },
    { label: 'Disable', action: onDisableBusiness, hidden: !business.isEnabled }
  ];

  const onPlanChange = useCallback(
    () => {
      setShowPlanModal(false);
      setRefetchPlan(refetchPlan + 1);
    },
    [setShowPlanModal, setRefetchPlan]
  );

  const [, , plan] = usePromise(
    () => client
      .properties.for(property.id)
      .businesses.for(business.id)
      .getPlan(),
    [property, business, refetchPlan],
    undefined,
    true
  );

  return (
    <View className="bg--gray brdr--b">
      <View.Section className="pb0">
        <div className="mb1">
          <div className="pull-right">
            <ActionMenu actions={actions} />
          </div>
          <div className="pull-left">
            <BusinessProfileImage imageId={business.profileImage} businessName={business.name} />
          </div>
          <div>
            <h3 className="mt0 mb0">
              {business && business.name}
            </h3>
            <div className="flex">
              {businessCategories && (
                <Label.Group>
                  {business.categories.map(category => <Label key={category.id}>{category.name}</Label>)}
                </Label.Group>
              )}
              {showBullet && (
                <div className="ml1 mr1" style={{ fontSize: '34px' }}>&bull;</div>
              )}
              <div className="flex aic">
                <p>{businessSlogan}</p>
              </div>
            </div>
          </div>
          {(business.isEnabled === false) && (
            <div className="mt1">
              <Label color="red">Disabled</Label>
            </div>
          )}
        </div>
        {plan && <div>Plan: {plan.name}</div>}
        <p className="small ml0 pt1">{business && business.description}</p>
        <div className="flex mb1">
          <label>Phone:</label>
          <p className="ml1">{businessPhone}</p>
        </div>
        {business && business.socialProfiles && business.socialProfiles.map(({ type, id }) => (
          <div className="flex mb1">
            <label>{getLabelForType(type)}:</label>
            <p className="ml1">{id}</p>
          </div>
        ))}
        <TagEditor fetchTags={fetchTags} onSave={saveTags} />
      </View.Section>
      <View.Section className="p0">
        <div className="mt2">
          <SubNav>
            <NavMenuItem to={`${props.basePath}/overview`} name="overview" />
            <NavMenuItem to={`${props.basePath}/offers`} name="offers" />
            <NavMenuItem to={`${props.basePath}/locations`} name="locations" />
            {businessDashboardEnabled && <NavMenuItem to={`${props.basePath}/users`} name="users" />}
          </SubNav>
        </div>
      </View.Section>
      {showPlanModal && (
        <ChangePlanModal
          business={business}
          property={property}
          onComplete={onPlanChange}
          onCancel={() => setShowPlanModal(false)}
        />
      )}
    </View>
  );
}

BusinessDetailsNav.propTypes = {
  business: PropTypes.object,
  property: PropTypes.object,
  fetchTags: PropTypes.func,
  saveTags: PropTypes.func,
  onEnableBusiness: PropTypes.func,
  onDisableBusiness: PropTypes.func,
  basePath: PropTypes.string
};

export default BusinessDetailsNav;
export { BusinessDetailsNav as Presenter };
