import {
  Box,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import React from 'react';

const Color = ({ valid, ...input }) => {
  return (
    <InputGroup>
      <Input {...input} key={input.name} />
      <InputRightElement>
        <Box p={1} w="3em" h="100%">
          <label>
            <Box
              w="100%"
              h="100%"
              background={valid ? input.value : undefined}
              border="1px dashed silver"
            />
            <Input
              w="5em"
              hidden
              type="color"
              value={input.value}
              onChange={input.onChange}
            />
          </label>
        </Box>
      </InputRightElement>
    </InputGroup>
  );
};

export default Color;
