import {
  Box,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Field,
} from 'react-final-form';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import Color from './Color';

const normalizeColor = (val) => {
  if (!val) return val;
  if (val.charAt(0) === '#') return val;
  return `#${val}`;
};

const ColorField = ({
  name,
  label,
  validate,
  format,
  parse = normalizeColor,
  info
}) => {
  return (
    <Field name={name} validate={validate} format={format} parse={parse}>
      {({ input, meta }) => {
        return (
          <FieldLabel label={label}>
            <Color valid={meta.valid} {...input} />
            <p className="text--muted">{info}</p>
            <FieldError name={name} chakra alwaysShow={false} />
          </FieldLabel>
        );
      }}
    </Field>
  );
};

ColorField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  format: PropTypes.func,
  parse: PropTypes.func,
  fluid: PropTypes.bool
};

export default ColorField;
