import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Button,
  Code,
  Flex,
  IconButton,
  Spacer,
  Text,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import { FormSpy } from 'web-react-ui/src/reactFinalForm';
import useRequest from 'shared/src/hooks/useRequest';
import SimpleForm2 from 'web-react-ui/src/reactFinalForm/SimpleForm2';
import { FaRegThumbsDown } from 'react-icons/fa';
import {
  compose,
  max,
  required,
} from 'web-react-ui/src/reactFinalForm/validators';
import {
  TextArea,
  TextField,
} from 'web-react-ui/src/reactFinalForm/fields';
import wrapSubmit from 'web-react-ui/src/reactFinalForm/wrapSubmit';
import businessModule from '../../../modules/businesses';
import propertyModule from '../../../modules/property';
import createDraft from './createDraft';
import FeedbackModal from './FeedbackModal';
import SuggestedImages from './SuggestedImages';
import Suggestion from './Suggestion.interface';
import FormData from './FormData.interface';

const SuggestionCard = ({ suggestion }: { suggestion: Suggestion }) => {
  const toast = useToast();

  const [editing, setEditing] = useBoolean();
  const [saved, setSaved] = useBoolean();
  const [showFeedback, setShowFeedback] = useBoolean();

  const property = useSelector((state: RootStateOrAny) => propertyModule.selectors.property.getData(state));
  const business = useSelector((state: RootStateOrAny) => businessModule.selectors.business.getData(state));

  const saveRequest = useRequest(
    // eslint-disable-next-line consistent-return
    async (formData: FormData) => {
      const newDraftId = await createDraft(formData, property.id, business.id);

      setEditing.off();
      setSaved.on();

      const offerHref = `/${property.id}/businesses/${business.id}/offers/${newDraftId}`;
      toast({
        variant: 'subtle',
        title: (
          <Flex gap="1em" fontSize="xl" alignItems="center">
            <Text fontSize="inherit"><Text as="b">{formData.headline}</Text> saved as draft.</Text>
            <a href={offerHref} target="_blank" rel="noreferrer">View Draft <ExternalLinkIcon /></a>
          </Flex>
        ),
        status: 'info',
        containerStyle: { minWidth: '80%' },
        duration: null,
        isClosable: true,
      });
    },
  );

  return (
    <Flex direction="column" w="100%" maxWidth={[null, null, 'calc(33% - 0.5em)']} p="1em" pb="3em">
      <ErrorAlert error={saveRequest.error} />
      <SimpleForm2
        onSubmit={wrapSubmit(saveRequest.run)}
        initialValues={suggestion}
        formProps={{ height: '100%' }}
        spinnerProps={{ height: '100%' }}
      >
        <Flex direction="column" gap="1em" h="100%">
          <SuggestedImages name="images" editable={editing} suggestion={suggestion} />
          {editing
            ? (
              <>
                <TextField
                  name="headline"
                  validate={compose(required, max(32))}
                  info="max 32"
                  wrapLabel
                  chakra
                  alwaysShowValidation
                />
                <TextField
                  name="subhead"
                  validate={compose(required, max(64))}
                  info="max 64"
                  wrapLabel
                  chakra
                  alwaysShowValidation
                />
                <TextArea
                  name="details"
                  validate={compose(required, max(400))}
                  info="max 400"
                  wrapLabel
                  chakra
                  alwaysShowValidation
                />
              </>
            )
            : (
              <FormSpy>
                {({ values }: { values: Suggestion }) => (
                  <>
                    <Text fontSize="2xl">{values.headline}</Text>
                    <Text fontSize="xl">{values.subhead}</Text>
                    <Text>{values.details}</Text>
                  </>
                )}
              </FormSpy>
            )
          }
          <Spacer />
          <Code>Keywords: {suggestion.keywords.join(', ')}</Code>
          {
            saved
              ? null
              : (
                <Flex gap="1em">
                  <>
                    <IconButton
                      isRound
                      icon={<FaRegThumbsDown />}
                      aria-label="Report Poor Suggestion"
                      onClick={setShowFeedback.on}
                    />
                    <Spacer />
                    <Button onClick={setEditing.toggle}>{editing ? 'Done Editing' : 'Edit'}</Button>
                    <Button colorScheme="blue" type="submit">Save Draft</Button>
                  </>

                </Flex>
              )
          }
        </Flex>
        <FormSpy>
          {({ values }: { values: Suggestion }) => (
            <FeedbackModal
              suggestion={values}
              isOpen={showFeedback} onClose={setShowFeedback.off}
              business={business}
            />
          )}
        </FormSpy>
      </SimpleForm2>
    </Flex>
  );
};

export default SuggestionCard;
