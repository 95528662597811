import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import auth from 'shared/src/modules/auth';
import { TextArea } from 'web-react-ui/src/reactFinalForm/fields';
import SimpleSelectField from 'web-react-ui/src/reactFinalForm/fields/SimpleSelectField';
import SimpleForm2 from 'web-react-ui/src/reactFinalForm/SimpleForm2';
import { required } from 'web-react-ui/src/reactFinalForm/validators';
import Business from 'web-react-ui/src/types/Business.interface';
import Suggestion from './Suggestion.interface';
import { SuggestionImageCarousel } from './SuggestionImageCarousel';

const FEEDBACK_EMAIL = 'support-5@getintheloop.ca.hs-inbox.com';

const issueValues = [
  'Incorrect products / services',
  'Promotion type not appropriate for business or product',
  'Bad headline or subhead',
  'Confusing or inconsistent copy',
  'Incorrect discount or prices',
  'Images do not match promotion content',
  'Other',
];

const issueOptions = [
  { text: 'Select Issue' },
  ...issueValues.map(issue => ({ text: issue, value: issue })),
];

const FeedbackModal = ({ suggestion, business, isOpen, onClose }: {
  suggestion: Suggestion,
  business: Business,
  isOpen: boolean,
  onClose: () => void
}) => {
  const user: { email: string } = useSelector(auth.selectors.getUser);

  const sendFeedback = useMemo(
    () => (values: { issue: string, comments?: string }) => {
      const body = `
Problem Type: ${values.issue}
Notes: ${values.comments || 'none'}

--- Suggestion ---
Business: ${business.name} (${business.id})
Headline: ${suggestion.headline}
Subhead: ${suggestion.subhead}
Details: ${suggestion.details}
Keywords: ${suggestion.keywords}
Images: ${suggestion.images?.map(v => `\n  * ${v}`).join('') ?? 'none'}

User: ${user.email}
`.trim();

      const link = new URL(`mailto:${FEEDBACK_EMAIL}`);
      link.searchParams.set('subject', `Feedback: ${values.issue}`);
      link.searchParams.set('body', body);
      window.open(link, '_blank');
      onClose();
    },
    [suggestion, business, user],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <SimpleForm2 initialValues={{}} onSubmit={sendFeedback}>
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Flex gap="1em" direction="column">
              <SuggestionImageCarousel value={suggestion.images || []} />
              <Text fontSize="2xl">{suggestion.headline}</Text>
              <Text fontSize="xl">{suggestion.subhead}</Text>
              <Text>{suggestion.details}</Text>
              <hr />
              <SimpleSelectField chakra name="issue" options={issueOptions} validate={required} />
              <TextArea chakra name="comments" wrapLabel placeholder="Notes (Optional)" />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" colorScheme="blue" ml="auto">Send Feedback</Button>
          </ModalFooter>
        </ModalContent>
      </SimpleForm2>
    </Modal>
  );
};

export default FeedbackModal;
