const ExternalIdTypes = [
  // inspecting source code for https://mysask411.com/saskatoon/brick-loft-event-co-ltd/1086895
  // <numbers>:<numbers>
  { value: 'directwest', label: 'DirectWest Listing ID', pattern: /^[0-9]+:[0-9]+$/ },
  // deduced by looking at example ids on yelp.com
  { value: 'yelp', label: 'Yelp Listing ID', pattern: /^[a-zA-Z0-9_-]+$/ },
  // https://stackoverflow.com/questions/29201332/google-places-api-place-id-format
  { value: 'google', label: 'Google Place', pattern: /^[a-zA-Z0-9_-]+$/ },
  // https://wiki.openstreetmap.org/wiki/Elements
  { value: 'osm', label: 'OSM Listing ID', pattern: /^(way|node)\\s\\d+$/ },
];

const externalIdSchemas: { [key: string]: any } = {
  // https://wiki.openstreetmap.org/wiki/Elements
  osm: /^(way|node)\s\d+$/,
  // https://stackoverflow.com/questions/29201332/google-places-api-place-id-format
  google: /^[a-zA-Z0-9_-]+$/,
  // deduced by looking at example ids on yelp.com
  yelp: /^[a-zA-Z0-9_-]+$/,
  // inspecting source code for https://mysask411.com/saskatoon/brick-loft-event-co-ltd/1086895
  // <numbers>:<numbers>
  directwest: /^[0-9]+:[0-9]+$/,
};

export {
  ExternalIdTypes,
  externalIdSchemas,
};
