import { Button, Flex, Icon } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const PageBackButton = ({
  to,
  onClick,
  children,
  disabled,
  fontSize,
}: {
  to?: string;
  onClick?: React.ComponentProps<typeof Button>["onClick"];
  children: ReactNode;
  disabled?: boolean;
  fontSize?: string;
}) => {
  return (
    <>
      {/* Invisible focus trap because Modal autoFocus={false} doesn't work on Safari */}
      <Button hidden={true} />
      <Flex
        color="blue.600"
        bg="none"
        to={to}
        onClick={onClick}
        as={onClick || disabled ? Button : Link}
        disabled={disabled}
        align="center"
        px="4"
        w="min-content"
        fontSize={fontSize || "inherit"} // Why do I have to set this?
      >
        <Icon as={BsChevronLeft} mr={3} />
        {children}
      </Flex>
    </>
  );
};
export default PageBackButton;
