import { Heading } from '@chakra-ui/react';
import React from 'react';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import OfferReviewList from './OfferReviewList';

const OfferReviewModal = () => {
  return (
    <BigModal open>
      <BigModal.Contents>
        <BigModal.Header>
          <PageBackButton to="../overview">Back</PageBackButton>
          <Heading>Quick Offer Review</Heading>
        </BigModal.Header>
        <BigModal.Body>
          <OfferReviewList />
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};

export default OfferReviewModal;
