import {
  chakra,
  Box,
  Divider,
  Flex,
  Grid,
  Image,
  Spacer,
  Text,
  Skeleton,
  Heading,
} from '@chakra-ui/react';
import React from 'react';
import InfoBadge from 'web-react-ui/src/chakra/offers/cells/InfoBadge';
import ScheduleDate from 'web-react-ui/src/chakra/offers/cells/ScheduleDate';
import Offer from 'web-react-ui/src/types/Offer.interface';
import iconizeImageUrl from 'web-react-ui/src/utils/iconizeImageUrl';
import legacyRedeemLabels from 'web-react-ui/src/components/offers/legacyRedeemLabels';
import useRequest from 'shared/src/hooks/useRequest';
import client from '../../../services/client';

const OfferReviewCard = ({ offer, propertyId, businessId }: {
  offer: Offer,
  propertyId: string,
  businessId: string
}) => {
  return (
    <Box p="1em" borderWidth="1px" shadow="md" w="60ch">
      <Flex direction="column" gap="0.5em" height="100%">
        <Flex gap="1em" alignItems="center">
          <Heading as="h1" fontSize="2xl" fontWeight="bold">{offer.headline}</Heading>
          <InfoBadge ml="auto" type={offer.status} />
        </Flex>
        <Heading as="h2" fontSize="xl">{offer.subHeadline}</Heading>
        {/*<pre>*/}
        {/*  {JSON.stringify(offer, null, 2)}*/}
        {/*</pre>*/}
        <Grid as="ul" gap="1em" templateColumns="repeat(3, fit-content(128px))">
          {offer.heroImageUrls.map((heroImage: string) => (
            <ImageThumb key={heroImage} url={iconizeImageUrl(heroImage, { size: 128 })} />
          ))}
        </Grid>
        <Text>{offer.details}</Text>
        <Divider sx={{ userSelect: 'none' }} />
        <RedemptionDetails offer={offer} propertyId={propertyId} businessId={businessId} />
        <ScheduleDate offer={offer} />
        <Divider opacity={0.1} />
      </Flex>
    </Box>
  );
};

const RedemptionDetails = ({ offer, propertyId, businessId }: {
  offer: Offer,
  propertyId: string,
  businessId: string
}) => {
  const offerFlowRequest = useRequest(
    async () => {
      const offerDetails = await client
        .properties.for(propertyId)
        .businesses.for(businessId)
        .offers.for(offer.id).coreDetails();

      if (!offerDetails.flowId) return {};

      return client
        .properties.for(propertyId)
        .businesses.for(businessId)
        .offers.for(offer.id)
        .flows.details(offerDetails.flowId);
    },
    [offer.id],
  );

  const redeemLabel = getRedeemLabel(offerFlowRequest.result);
  const redeemAction = getRedeemAction(offerFlowRequest.result);

  return (
    <Skeleton isLoaded={offerFlowRequest.settled} minH="1em">
      <Text>
        {redeemLabel}
        {
          redeemAction
            ? (` • ${redeemAction}`)
            : null
        }
      </Text>
    </Skeleton>
  );
};

const getRedeemLabel = (offerFlow) => {
  if (!offerFlow) return '';
  const rawValue = offerFlow?.vars?.redeemedTransitionButtonText;
  const mapping = Object
    .entries(legacyRedeemLabels)
    .find(([label, token]) => token === rawValue);
  return mapping?.[0] ?? rawValue;
};

const getRedeemAction = (offerFlow) => {
  if (!offerFlow) return '';
  return offerFlow?.vars?.redeemedActionTarget;
};

const ImageThumb = ({ url }: { url: string }) => {
  return <chakra.li as={Image} src={url} alt={url} />;
};

export default OfferReviewCard;
