import {
  Box,
  Flex,
  Skeleton,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import usePagedList from 'shared/src/hooks/usePagedList';
import React from 'react';
import Offer from 'web-react-ui/src/types/Offer.interface';
import propertyModule from '../../../modules/property';
import businessesModule from '../../../modules/businesses';

import client from '../../../services/client';
import OfferReviewCard from './OfferReviewCard';


const fetchOffers = ({ propertyId, businessId }) => {
  return client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .offers.list({
      status: ['live', 'scheduled', 'disabled', 'draft'],
    });
};

const OfferReviewList = () => {
  const property = useSelector(state => propertyModule.selectors.property.getData(state));
  const business = useSelector(state => businessesModule.selectors.business.getData(state));
  const offersPagedList = usePagedList(
    fetchOffers,
    {
      propertyId: property?.id,
      businessId: business?.id,
    },
  );

  return (
    <Flex gap="1em" wrap="wrap" justifyContent="center">
      {offersPagedList.items.map((offer: Offer) => (
        <OfferReviewCard key={offer.id} offer={offer} propertyId={property.id} businessId={business.id} />
      ))}
      {
        offersPagedList.loading
          ? (
            <>
              <Skeleton minH="40ch" w="60ch" />
              <Skeleton minH="40ch" w="60ch" opacity="0.5" />
              <Skeleton minH="40ch" w="60ch" opacity="0.25" />
            </>
          )
          : null
      }
    </Flex>
  );
};

export default OfferReviewList;
