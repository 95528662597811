import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Request from 'web-react-ui/src/data/Request';
import Message from 'web-react-ui/src/components/collections/Message';
import LocationDetails from 'web-react-ui/src/chakra/LocationDetails';
import Location from 'web-react-ui/src/types/Location.interface';
import Business from 'web-react-ui/src/types/Business.interface';
import Property from 'web-react-ui/src/types/Property.interface';

import propertyModule from "../modules/property";
import LocationsNav from '../components/businesses/locations/LocationsNav';
import LocationOverview from '../components/businesses/locations/LocationOverview';
import LocationOffers from '../components/businesses/locations/LocationOffers';
import businessModule from "../modules/businesses";
import client from "../services/client";

const fetchLocation = ({
  property,
  business,
  locationId
}: {
  property: Property,
  business: Business,
  locationId: string,
}) => {
  if (!business) return null;
  return client
    .properties.for(property.id)
    .businesses.for(business.id)
    .locations.for(locationId)
    .details();
};

const fetchExternalIds = ({
  propertyId,
  businessId,
  locationId,
}: {
  propertyId: string,
  businessId: string,
  locationId: string,
}) => {
  if (!propertyId || !businessId || !locationId) return null;
  return client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .locations.for(locationId)
    .getExternalPlaceIds();
};

interface MatchParams {
  locationId: string;
}

interface Props extends RouteComponentProps<MatchParams> { }

const LocationDetailsRoutes: React.FC<Props> = ({ match }): JSX.Element => {
  const [disabling, setDisabling] = useState(false);
  const [redirect, setRedirect] = useState<string>('');
  const [externalIds, setExternalIds] = useState<Record<string, string>>();

  const property = useSelector(state => propertyModule.selectors.property.getData(state));
  const business = useSelector(state => businessModule.selectors.business.getData(state));

  const locationId = match.params.locationId;

  useEffect(() => {
    fetchExternalIds({
      propertyId: property.id,
      businessId: business.id,
      locationId
    }).then((data: { [key: string]: any }) => {
      setExternalIds(data);
    });
  }, []);

  const handleDisable = () => {
    setDisabling(true);
    client
      .properties.for(property.id)
      .businesses.for(business.id)
      .locations.for(locationId)
      .disable()
      .then(() => {
        setDisabling(false);
        setRedirect(`/${property.id}/businesses/${business.id}/locations`);
      });
  };

  const fetchTags = () => {
    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .locations.for(locationId)
      .getTags()
      .then((tags: any) => tags.items);
  };

  const saveTags = (newTags: any) => {
    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .locations.for(locationId)
      .updateTags(newTags);
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <Box maxW='7xl' mx='auto' p={5}>
      <Request
        request={fetchLocation}
        params={{ property, business, locationId }}
      >
        {({ data, loading, error }: {
          data: Location,
          loading: boolean,
          error: { message: string }
        }) => {
          return (
            <WaitFor
              waitFor={!disabling && !loading}
              wrapContents={false}
              inline={false}
              size={undefined}
              suppressSpinner={false}
              loaderChildren={undefined}
            >
              {error && <Message error>{error.message}</Message>}
              <Box mb={8}>
                <Flex justify='space-between' align='center'>
                  <Heading>Location Details</Heading>
                  <Button onClick={handleDisable}>Disable</Button>
                </Flex>
                <LocationDetails
                  location={data}
                  externalIds={externalIds}
                  showTagEditor={true}
                  fetchTags={fetchTags}
                  saveTags={saveTags}
                  isLoading={false}
                />
              </Box>
              <LocationsNav basePath={match.url} />
              <Switch>
                <Route
                  path={`${match.url}/offers`}
                  render={() => (
                    <LocationOffers
                      propertyId={property?.id}
                      businessId={business?.id}
                      locationId={locationId}
                    />
                  )}
                />
                <Route
                  path={`${match.url}/stats`}
                  render={() => (
                    <LocationOverview
                      propertyId={property?.id}
                      businessId={business?.id}
                      locationId={locationId}
                    />
                  )}
                />
                <Redirect to={`${match.url}/offers`} />
              </Switch>
            </WaitFor>
          );
        }}
      </Request>
    </Box>
  );
};

export default LocationDetailsRoutes;
