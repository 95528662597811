/* eslint arrow-body-style: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import BooleanField from '../../reactFinalForm/fields/BooleanField';
import NumberField from '../../reactFinalForm/fields/NumberField';
import SelectField from '../../reactFinalForm/fields/SelectField';
import { min } from '../../reactFinalForm/validators';
import Divider from '../elements/Divider';

const FieldTypes = {
  SWITCH: 'bool',
  NOT_NEGATIVE_LIMIT: 'non_neg_int',
  NULLABLE_BOOLEAN: 'bool3',
  ENUM: 'enum'
};

// SelectField cannot handle a value of `null`
const NULL_TOKEN = 'NULL_TOKEN';
const parseNull = val => ((val === NULL_TOKEN) ? null : val);
const formatNull = val => ((val === null) ? NULL_TOKEN : val);
const NULLABLE_BOOL_OPTIONS = [
  { text: 'True', value: true },
  { text: 'False', value: false },
  { text: 'None', value: NULL_TOKEN }
];


const PlanSettingsFields = ({ baseName = '', settingGroups, disabled }) => {
  return (
    <React.Fragment>
      {settingGroups && settingGroups.map((group) => {
        return (
          <div key={group.group}>
            <h3 className="mb0 pt4">{group.group}</h3>
            <Divider className="pb2" fitted />
            {
              group.settings.map((setting) => {
                const { schema, key, desc } = setting;
                if (schema === FieldTypes.NULLABLE_BOOLEAN) {
                  return (
                    <SelectField
                      key={key}
                      label={desc}
                      name={`${baseName}${key}`}
                      options={NULLABLE_BOOL_OPTIONS}
                      disabled={disabled}
                      parse={parseNull}
                      format={formatNull}
                    />
                  );
                }
                if (schema === FieldTypes.SWITCH) {
                  return (
                    <BooleanField
                      key={key}
                      label={desc}
                      name={`${baseName}${key}`}
                      toggle
                      disabled={disabled}
                    />
                  );
                }
                if (schema === FieldTypes.NOT_NEGATIVE_LIMIT) {
                  return (
                    <NumberField
                      key={key}
                      label={desc}
                      name={`${baseName}${key}`}
                      validate={min(0)}
                      wrapLabel
                      required
                      disabled={disabled}
                    />
                  );
                }
                if (schema === FieldTypes.ENUM) {
                  return (
                    <SelectField
                      key={key}
                      label={desc}
                      name={`${baseName}${key}`}
                      options={setting.enums.map(option => ({ text: option, value: option }))}
                      disabled={disabled}
                      parse={parseNull}
                      format={formatNull}
                    />
                  );
                }
                return null;
              })
            }
          </div>
        );
      })}
    </React.Fragment>
  );
};

PlanSettingsFields.propTypes = {
  baseName: PropTypes.string,
  settingGroups: PropTypes.array,
  disabled: PropTypes.boolean
};

export default PlanSettingsFields;
