/* eslint max-len: 0 */
import {
  Button,
  Container,
  Flex,
  Heading,
} from '@chakra-ui/react';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useRequest from 'shared/src/hooks/useRequest';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import GeneratingSuggestions from './GeneratingSuggestions';
import Suggestion from './Suggestion.interface';
import SuggestionCard from './SuggestionCard';
import businessModule from '../../../modules/businesses';
import client from '../../../services/client';

const runSuggestions = async ({ businessId }: { businessId: string }) => {
  return client
    .businesses.for(businessId)
    .runOfferSuggestionTask();
};

const fetchSuggestions = async ({ businessId, taskId }: { businessId: string, taskId: string }) => {
  if (!taskId) return null;

  return client
    .businesses.for(businessId)
    .awaitOfferSuggestionTask(taskId);
};

const SuggestModal = () => {
  const [taskId, setTaskId] = useQuerystringParam('taskId', '', { squash: true });

  const business = useSelector((state: RootStateOrAny) => businessModule.selectors.business.getData(state));

  const suggestionTaskRequest = useRequest(
    async () => {
      if (taskId) return null;
      const task = await runSuggestions({ businessId: business.id });
      return setTaskId(task.id);
    },
    { businessId: business.id, taskId },
    { discardError: true },
  );

  const suggestionResultsRequest = useRequest(
    fetchSuggestions,
    { businessId: business.id, taskId },
    { discardError: true },
  );

  const retry = useMemo(
    () => () => {
      setTaskId(null);
    },
    [business],
  );

  const loading = suggestionTaskRequest.loading || suggestionResultsRequest.loading;

  return (
    <BigModal open>
      <Container className="c-big-modal" maxW="container.xl">
        <BigModal.Header>
          <PageBackButton to="../overview">Back</PageBackButton>
          <Flex alignItems="top" gap="1em" p="1em" pb="0">
            <Heading w="100%" pb="2">Promotions AI!</Heading>
            <Button
              onClick={retry}
              flexShrink={0}
              isLoading={loading}
              variant="outline"
            >
              Regenerate Suggestions
            </Button>
          </Flex>
        </BigModal.Header>
        <BigModal.Body pb="5em">
          <ErrorAlert
            error={suggestionResultsRequest.error || suggestionTaskRequest.error}
            title="Error Generating Suggestions"
            message={<Button onClick={retry} variant="link">Please try again</Button>}
          />
          {loading
            ? (
              <GeneratingSuggestions />
            )
            : (
              <Flex
                direction={['column', 'column', 'row']}
                gap="1em"
                justifyItems="stretch"
                wrap="wrap"
              >
                {suggestionResultsRequest.result?.map(
                  (s: Suggestion, idx: number) => <SuggestionCard suggestion={s} key={idx} />,
                )}
              </Flex>
            )
          }
        </BigModal.Body>
      </Container>
    </BigModal>
  );
};

export default SuggestModal;
