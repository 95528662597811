import { Flex } from '@chakra-ui/react';
import React from 'react';
import fieldRenderers from './fieldRenderers';

const ExternalListings = ({ externalListings, fieldWhitelist }: {
  externalListings: Record<string, string>,
  fieldWhitelist: Array<string>
}) => {
  const entries = Object.entries(externalListings || {})
    .filter(([key, value]) => {
      if (!value) return false;
      if (fieldWhitelist && !fieldWhitelist.includes(key)) return false;
      return true;
    });

  return (
    <Flex direction="column" gap="1em">
      {
        entries.map(([sid, value]: [string, string]) => {
          const Renderer = fieldRenderers[sid] || fieldRenderers.default;
          return <Renderer sid={sid} value={value} />;
        })
      }
    </Flex>
  );
};

export default ExternalListings;
