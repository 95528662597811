import React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Link,
  Spinner,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { getLabelForType } from 'shared/src/constants/socialProfiles';

import Location from '../../types/Location.interface';
import Map from '../../map/Map';
import TagEditor from '../../components/tags/TagEditor';
import ExternalListings from '../../components/locations/ExternalListings';

const LocationDetail = ({ label, value }: { label: string, value?: any }) => {
  return (
    <Flex w="100%">
      <Box w="25%" minW="125px" mr={2}>
        <Text fontSize="lg" fontWeight={600} textTransform="capitalize">{label}</Text>
      </Box>
      <Box>
        {typeof value === 'string' || typeof value === 'number' ? (
          <Text fontSize="lg" fontWeight={400}>
            {value}
          </Text>
        ) : value}
      </Box>
    </Flex>
  );
};

const LocationDetails = ({
  location,
  externalIds,
  showTagEditor,
  fetchTags,
  saveTags,
  isLoading,
  externalListingsWhitelist,
}: {
  location?: Location,
  externalIds?: Array<{ key: string, value: any }>,
  showTagEditor?: boolean,
  fetchTags?: () => void,
  saveTags?: (newTags: any) => void,
  isLoading?: boolean,
  externalListingsWhitelist?: Array<strings>
}) => {
  console.log('externalIds', externalIds);

  const centre = location?.address?.centre &&
    [location.address.centre.lat, location.address.centre.long];
  const markers = centre && [{ center: centre }];

  return (
    <Box mt={8}>
      {isLoading ? (<Center><Spinner /></Center>) : (
        <>
          <Heading as="h5" fontSize="2xl" borderBottom="1px" borderColor="gray.200" pb={3}>
            {location?.name}
          </Heading>
          <Stack direction={{ base: 'column', lg: 'row' }} spacing={8} align="flex-start" py={8}>
            <Box minW="50%">
              <VStack spacing={8} divider={<StackDivider />}>
                <VStack spacing={5} w="100%">
                  <LocationDetail label="Address" value={location?.address.label || 'No address set'} />
                  <LocationDetail label="Phone" value={location?.phone || 'No phone set'} />
                  <LocationDetail
                    label="Website"
                    value={location?.url ? (
                      <Button
                        variant="link"
                        as={Link}
                        textDecoration="underline"
                        fontSize="xl"
                        href={location.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        rightIcon={<ExternalLinkIcon />}
                      >
                        {location.url}
                      </Button>
                    ) : (
                      'No Website Set'
                    )}
                  />
                  {location?.socialProfiles?.map(({ type, id }) => {
                    return <LocationDetail key={type} label={getLabelForType(type) || type} value={id} />;
                  })}
                </VStack>
                {showTagEditor && (
                  <Box w="100%">
                    <Heading fontSize="xl" mb={5}>Tags</Heading>
                    <TagEditor fetchTags={fetchTags} onSave={saveTags} />
                  </Box>
                )}
                <Box w="100%">
                  <Heading fontSize="xl" mb={5}>External Listings</Heading>
                  <ExternalListings externalListings={externalIds} fieldWhitelist={externalListingsWhitelist} />
                </Box>
              </VStack>
            </Box>
            <Map
              initialCenter={centre}
              initialZoom={15}
              markers={markers}
            />
          </Stack>
        </>
      )}
    </Box>
  );
};

export default LocationDetails;
